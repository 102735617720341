<template>
  <Breadcrumbs title="" main="Pages" />
  <div class="container-fluid">
    <div class="faq-wrap">
      <div class="row">
        <div class="col-xl-6 col-sm-12 box-col-12">
          <div class="card bg-primary">
            <div class="card-body">
              <div class="media faq-widgets">
                <div class="media-body">
                  <h2>เกี่ยวกับเรา</h2>
                  <p>
                    ระบบรับสมัครงานวิ่ง ลงทะเบียนงานวิ่ง | RUNRACE.IN.TH <br><u>ระบบรับสมัครงานวิ่ง ราคาถูก มีบริการครบทุกฟังชั่นการใช้งาน</u>
                  </p>
                  <p>ระบบรับสมัครงานวิ่ง รูปวิ่ง ทีมงาน พร้อมให้บริการ สนใจติดต่อสอบถามข้อมูลเพิ่มเติมได้ที่ <u>090494998เก้า</u></p>
                </div>
                <vue-feather type="award"></vue-feather>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-sm-12 box-col-12">
          <div class="card bg-primary">
            <div class="card-body">
              <div class="media faq-widgets">
                <div class="media-body">
                  <h2>ติดต่อเรา</h2>
                  <p>97/88 regent orchid เทอดไท 33 ธนบุรี กรุงเทพ 10600</p>
                  <p >แฟนเพจ: <a style="color: aliceblue;text-decoration: underline;" target="_blank" href="https://facebook.com/runrace.in.th">https://facebook.com/runrace.in.th</a></p>
                  <p>E-mail: runrace.in.th@gmail.com</p>
                </div>
                <vue-feather type="mail"></vue-feather>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-7 col-sm-12 box-col-12">
          <div class="card bg-primary">
            <div class="card-body">
              <div class="media faq-widgets">
                <div class="media-body">
                  <h2>พันธกิจ</h2>
                  <h5>
                    ร่วมพัฒนาไปด้วยกัน #พัฒนาคน #พัฒนางาน #พัฒนาระบบ
                    สู่มาตรฐานเดียวกัน
                  </h5>
                  <b style="text-align: right">We will make perfect for all</b>
                </div>
                <vue-feather type="hash"></vue-feather>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-5 box-col-5">
          <div class="card">
            <div class="card-body">
              <div class="media faq-widgets">
                <img
                  style="align-items: center; width: 440px"
                  src="../../assets/images/logo/logo.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'

export default {

  setup() {
    useHead({
      title: computed(() => `เกี่ยวกับเรา-ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH`),
      meta: [
        {
          name: `description`,
          content: computed(() => `ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH`),
        },
        {
          name: `keywords`,
          content: computed(() => `เกี่ยวกับเรา,ระบบรับสมัครงานวิ่ง,ค้นหารูปวิ่ง, หารูปวิ่ง,งานวิ่ง,runrace,runninghub`),
        }
        ],
     
    })
  }


};
</script>